import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';
import { mapNodesToData } from '../utils/helpers';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import Seo from '../components/common/Seo';
import { getSrc } from 'gatsby-plugin-image';
import ItemListSchema from '../components/common/ItemListSchema';

const GraphQLError = loadable(() => import('../components/error'));
const Layout = loadable(() => import('../layout'));
const ArchiveProduct = loadable(() => import('../components/archive-best-new-product'));

export const Head = ({ location, params, data, pageContext }) => {
  const {
    prismicCategory: {
      data: { meta_title, meta_description, noindex, banner },
    },
    allShopifyProduct,
  } = data;

  const seo = {
    title: meta_title,
    description: meta_description,
    keywords: [],
    hideScript: true,
    location,
  };

  return (
    <Seo {...seo}>
      <ItemListSchema products={allShopifyProduct?.nodes} />
      <meta name="robots" content={noindex ? 'NOINDEX,NOFOLLOW' : 'INDEX,FOLLOW'} />
      {banner?.gatsbyImageData && <link rel="preload" as="image" href={getSrc(banner)} />}
    </Seo>
  );
};

const NewArrrivalsPage = (props) => {
  const { data, errors, location } = props;
  if (errors) {
    return <GraphQLError errors={errors} />;
  }

  const { fgcCollections, allShopifyProduct, prismicCategory, subCategories } = data;
  const [state, setState] = useState({ dataPage: prismicCategory, products: [], loading: true });

  useEffect(() => {
    prismicCategory.data.subcategories = mapNodesToData(subCategories);

    const objProducts = {},
      objManual = {};

    allShopifyProduct?.nodes?.forEach(
      (v) => (objProducts[v.shopifyId] = { ...v, best_sellers: 500 })
    );

    fgcCollections?.manual?.map(({ id }, i) => {
      const product = objProducts?.[id];
      if (product) {
        objManual[id] = product;
        delete objProducts[id];
      }
    });

    fgcCollections?.best_sellers?.map(({ id }, i) => {
      const product = objManual?.[id];
      if (product) objManual[id] = { ...product, best_sellers: i };
    });

    const products = Object.values({ ...objManual, ...objProducts });

    setState({ dataPage: prismicCategory, products });
  }, []);

  return (
    <Layout location={location}>
      <ArchiveProduct state={state} promotion={{}} />
    </Layout>
  );
};

export default withPrismicPreview(NewArrrivalsPage);

export const query = graphql`
  query ($uid: String!, $url: String!, $title: [String]!) {
    prismicCategory(uid: { eq: $uid }) {
      uid
      data {
        name
        url
        default_sort
        parent_categories {
          category {
            uid
            document {
              ... on PrismicCategory {
                data {
                  name
                  url
                }
              }
            }
          }
        }
        meta_title
        meta_description
        h1_title
        noindex
        featured
        link {
          uid
          type
          url
          link_type
          document {
            ... on PrismicCategory {
              data {
                name
                url
              }
            }

            ... on PrismicBrand {
              data {
                name
                url
              }
            }
          }
        }
        banner {
          alt
          gatsbyImageData
          thumbnails {
            mobile {
              gatsbyImageData
              alt
            }
          }
        }
        bottom_description {
          richText
        }
        promotion_link {
          uid
          link_type
          type
        }
        promotion_image {
          gatsbyImageData(width: 280, breakpoints: 280)
          alt
        }
        promotion_lifestyle_image {
          alt
          gatsbyImageData(width: 280, breakpoints: 280)
        }
        promotion_sub_title
        promotion_tag
        promotion_title
        promotion_button_text
      }
    }

    subCategories: allPrismicCategory(
      filter: {
        data: {
          parent_categories: { elemMatch: { category: { uid: { eq: $uid } } } }
          url: { regex: $url }
        }
      }
    ) {
      nodes {
        data {
          name
          url
        }
      }
    }

    fgcCollections(title: { in: $title }) {
      manual {
        id
      }
      best_sellers {
        id
      }
    }

    allShopifyProduct(
      filter: {
        priceRangeV2: { maxVariantPrice: { amount: { ne: "0.0" } } }
        collections: { elemMatch: { title: { in: $title } } }
      }
      sort: { fields: createdAt, order: DESC }
      limit: 200
    ) {
      nodes {
        id
        title
        handle
        legacyResourceId
        shopifyId
        totalInventory
        productType
        collections {
          title
        }
        metafields {
          value
          key
        }
        hasOutOfStockVariants
        priceRangeV2 {
          maxVariantPrice {
            amount
          }
          minVariantPrice {
            amount
          }
        }
        images {
          shopifyId
          gatsbyImageData(width: 280, formats: WEBP, breakpoints: 280)
          altText
        }
        variants {
          id
          storefrontId
          price
          compareAtPrice
          sku
          shopifyId
          selectedOptions {
            name
          }
        }
        vendor
        createdAt
      }
    }
  }
`;
